.FilesContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

.FileItem {
    border-radius: 4px;
    border: 1px solid #CED4DA;
    padding: 12px;
}

.Table {
    min-height: fit-content !important;
}