.SpecialisationItem {
    max-width: 133px;
    border-radius: 20px;
    border: 1px solid #CED4DA;
    padding: 4px 12px;
    line-height: 1;
    font-size: 12px;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.SpecialisationOneItem {
    max-width: 300px;
}

.SpecialisationFullItem {
    max-width: fit-content;
    padding: 6px 12px;
}

.SpecialisationAdditionalItem:hover {
    border-color: #74788D;
}