//Partners
$black-base-1:       #062021;
$black-base-3:       #657276;
$black-base-4:       #97A5A9;
$black-base-5:       #DDE2E3;
$black-base-6:       #F2F7FB;

$accent-blue:        #3ECAE3;
$accent-blue--hover: #237699;
$white:              #FFFFFF;
$system-red:         #FF3C00;
$opacity-red:        #FF3C00BF;

$font-size-value--default: 16px;

.Modal {
    max-width: 594px !important;
    width: 594px !important;
}

.ModalContent {
    border-radius: 16px !important;
}

.ModalHeader {
   padding: 24px !important;
}

.ModalBody {
    padding: 24px !important;
}

.ModalActions {
    margin: 0;
    padding: 12px !important;
}

.BtnPrimary {
   border-radius: 36px !important;
   height: 48px !important;
   font-size: $font-size-value--default !important;
   background-color: $black-base-1 !important;
   border: none !important;
   padding: 13px 32px !important;
   line-height: 1 !important;
}

.BtnPrimary:hover {
  background-color: $black-base-3 !important;
}

.BtnPrimary:disabled, .BtnPrimary.disabled {
  color: $black-base-4 !important;
  background-color: $black-base-5 !important;
  border: none !important;
}

.BtnSecondary {
  border-radius: 36px !important;
  height: 48px !important;
  font-size: $font-size-value--default !important;
  background-color: $white !important;
  border-color: $black-base-5 !important;
  color: $black-base-1 !important;
  padding: 13px 32px !important;
  line-height: 1 !important;
}

.BtnSecondary:hover {
   border-color: $accent-blue !important;
}

.TextBlack {
  color: $black-base-1;
}

.TextSecondary {
  color: $black-base-3;
}

.TextMuted {
  color: $black-base-4;
}

.TextPrimary {
    color: $accent-blue;
}

.ModalNote {
    margin-top: 16px;
    background-color: $black-base-6;
    color: $black-base-3;
    padding: 16px;
    border-radius: 16px;
    line-height: 140%;
}

.ModalDropzoneFile {
    border: 1px solid $black-base-5;
    border-radius: 16px;
    padding: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ModalDropzoneFileContainer {
    width: 100%;
}

.FileItem {
    border: 1px solid $black-base-5;
    border-radius: 16px;
    padding: 16px;
}

.FileName {
    max-width: 450px;
    word-break: break-word;
}

.FileNameStep2 {
    max-width: 170px;
    word-break: break-word;
}

.Spinner {
    border: var(--bs-spinner-border-width) solid $accent-blue;
    border-right-color: transparent;
}