@import "variables";

html {
  scroll-behavior: smooth;
}

body .button-spinner {
  width: 14px !important;
  height: 14px !important;
}

.select-none {
  user-select: none;
}

.noti-icon .badge {
  left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 100px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.datepicker-fixed-icon .react-datepicker__custom-icon {
  right: 24px;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

body .rangeslider-horizontal {
  background: linear-gradient(270deg, #59cba1 0%, #ffffff 52.27%, #f28484 100%);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  margin-top: 12px;
  margin-bottom: 12px;

  .rangeslider__fill {
    background-color: transparent;
    border-radius: 15px;
  }
}

.divider {
  height: 1px;
  border-bottom: 1px solid #f2f2f2;
}

.cursor-pointer {
  cursor: pointer;
}

.no-outline {
  outline: none !important;
}

.no-border {
  border: none !important;
}

.w-90 {
  width: 90% !important;
}

.w-fit-content {
  width: fit-content !important;
}

.mh-100 {
  height: 100vh;
}

.vh-50 {
  height: 50vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.3s ease;
}

.rotate-360 {
  transform: rotate(0);
  transition: 0.3s ease;
}

body .pointer-events {
  pointer-events: auto !important;
}

body .align-right {
  text-align: right;
}

body .select-invalid > div:first-of-type {
  border: 1px solid #f46a6a;
}

body .pointer-events-none {
  pointer-events: none;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #74788d;
  }
}

.word-break {
  word-break: break-word !important;
}

body .text-yellow {
  color: #fde71f;
}

body section.border-error {
  border: 1px solid #f46a6a !important;
}

.nav-tabs > li > a {
  color: #74788d;
}

.disabled-pagination {
  color: #ced4da;
}

.deactivated-pill {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  background-color: rgba(241, 180, 76, 0.18);
  border-radius: 4px;
  color: #f1b44c;
  padding: 0 4px;
}

.inactivated-pill {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  background-color: #f46a6a2e;
  border-radius: 4px;
  color: #f46a6a;
  padding: 0 4px;
}

.active-pill {
  background-color: rgba(52, 195, 143, 0.18);
  border-radius: 4px;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #34c38f;
  padding: 0 4px;
}

.blocked-pill {
  background-color: #4950572E;
  border-radius: 4px;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #495057;
  padding: 0 4px;
}

.waitlisted-pill {
  background-color: #50A5F12E;
  border-radius: 4px;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #50A5F1;
  padding: 0 4px;
}

.study-link-width {
  width: 345px;
  margin-left: 28px;
}

.opacity-30 {
  opacity: 0.3;
}

.cursor-default {
  cursor: default !important;
}

.outline-none {
  outline: none !important;
}

.line-clamp-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: pre-line;
}

.active-arrow {
  .has-arrow {
    &:after {
      transform: rotate(-180deg);
    }
  }
}

body div.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.min-w-1-4 {
  min-width: 25%;
}

.min-w-1-5 {
  min-width: 20%;
}

.w-1-5 {
  width: 20%;
}

.w-1-3 {
  width: 33%;
}

.h-82 {
  height: 82%;
}

.top-m-15 {
  top: -15px;
}

.p-relative {
  position: relative;
}

.biomarker-range-arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 10px solid black;
}

$biomarker-range-red: #ff3c00;
$biomarker-range-yellow: #fbe754;
$biomarker-range-orange: #ff7f00;
$biomarker-range-green: #00c56f;

.biomarker-range-red {
  background-color: $biomarker-range-red;
}
.biomarker-range-green {
  background-color: $biomarker-range-green;
}
.biomarker-range-yellow {
  background-color: $biomarker-range-yellow;
}
.biomarker-range-orange {
  background-color: $biomarker-range-orange;
}

.biomarker-range-red-text {
  color: $biomarker-range-red;
}
.biomarker-range-green-text {
  color: $biomarker-range-green;
}
.biomarker-range-yellow-text {
  color: $biomarker-range-yellow;
}
.biomarker-range-orange-text {
  color: $biomarker-range-orange;
}

.opacity-20 {
  opacity: 20%;
}

.w-10 {
  width: 10%;
}

.h-12px {
  height: 12px;
}

.plan-of-action-accordion .accordion-button {
  padding: 1rem 0;
}

.plan-of-action-accordion .accordion-body {
  padding: 16px 0;
}

.level-title {
  font-weight: 600;
  border-bottom: 1px solid #CED4DA;
  padding: 0 1rem 1rem 1rem;
}

.action-number {
  width: 16px;
}

.action-type {
  width: 160px;
}

.biomarker-range-label {
  color: #74788d;
  margin-left: -5px;
}

.biomarker-range-title {
  color: $gray-700;
}

.f-10px {
  font-size: 10px;
}

.csvViewerModal {
  width: 880px;
  max-width: 880px;

  &.foodIntoleranceModal {
    width: 900px;
    max-width: 900px;
  }

  .modal-content {
    height: 90vh;
    overflow: hidden;
  }
  .table-spinner {
    width: 100% !important;
  }

  .uploaded-tag {
    font-size: 13px;
  }
  .uploaded-val {
    font-size: 13px;
    font-weight: 100;
  }

  .meta-table,
  .food-table,
  .dna-table {
    tbody {
      td {
        background-color: white;
      }
    }
  }

  .meta-table {
    height: 25vh;
  }
  .food-table,
  .dna-table {
    height: 50vh;
  }
}

.button-icon {
  width: 16px;
  height: 16px;

  path {
    stroke: currentColor;
  }
}

.color-picker-thumbnail {
  width: 36px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid var(--Skote-template-border, #ced4da);
}

.color-picker {
}

.color-picker-popover {
  position: absolute;
  top: 0;
  left: 55px;
  z-index: 2;
}

.color-picker-cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.pl-0 {
  padding-left: 0;
}

.formik-file-input {
  border: 1px solid #ced4da;
  border-radius: 4px;
  .selected-file-container__selected-file-name {
    color: #74788d;
  }
  .font-semibold {
    font-weight: 600;
  }
  .formik-file-input-logo {
    max-width: 80px;
    max-height: 80px;
  }
}

.bg-light-gray {
  background: #eff2f6 !important;
}

.min-h-auto {
  min-height: auto !important;
}

.form-control {
  min-height: 38px;
}

.hide-scrollbar {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.ui-customisation {
  .sideform-wrapper {
    padding: 0 20px 0 20px;
    .sideform-header {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 24px;
    }
  }
  .side-form-container {
    border-right: 1px solid var(--Skote-template-border, #ced4da);
    padding: 0 20px;
  }
  .nav-tabs-custom {
    border-bottom: 1px solid var(--Skote-template-border, #ced4da);
  }
  .accordion-description {
    margin-top: 0px;
    color: #74788d;
  }
  .input-subgroup {
    border-bottom: 1px solid #ced4da;
  }

  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    margin-right: 10px;
    outline: 0;
    background: #ced4da;
    min-height: 4px;
    padding: 0;
    border: none;
    border-radius: 0;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 11px;
    width: 11px;
    rotate: 45deg;
    margin-top: -1px;
    border-radius: 0;
    background: #f8f8fb;
    border: 2px solid #556ee6;
    cursor: pointer;
  }

  /* All the same stuff for Firefox */
  input[type="range"]::-moz-range-thumb {
    height: 11px;
    width: 11px;
    rotate: 45deg;
    margin-top: -1px;
    border-radius: 0;
    background: #f8f8fb;
    border: 2px solid #556ee6;
    cursor: pointer;
  }

  /* All the same stuff for IE */
  input[type="range"]::-ms-thumb {
    height: 11px;
    width: 11px;
    rotate: 45deg;
    margin-top: -1px;
    border-radius: 0;
    background: #f8f8fb;
    border: 2px solid #556ee6;
    cursor: pointer;
  }

  .range-container {
    .labels {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;

      span {
        color: #ced4da;
        font-size: 10px;
        background-color: #f8f9fa;
        padding: 0 4px;
      }
    }
    .range-tooltip {
      position: absolute;
      top: 0;
      font-size: 10px;
      background-color: #556ee6;
      color: #f8f8fb;
      padding: 0 4px;
    }
  }
  .shape-preview {
    justify-content: center;
    width: 36px;
  }
  .color-picker-tag {
    line-height: 140%;
    padding: 4px 12px;
    font-size: 16px;
    font-weight: 400;
    color: #062021;
    border-radius: 15px;
  }
  .accordion-item:first-child {
    .accordion-header {
      .accordion-button {
        padding-top: 0;
      }
    }
  }

  .email-template-section {
    margin-bottom: 48px;

    h2 {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .email-template-input {
      margin-top: 12px;
    }

    .editor-container {
      margin-top: 0;
    }
  }
}

.btn-download-csv svg path {
  fill: #556ee6;
}

.btn-download-csv:hover svg path {
  fill: #ffffff;
}

// .jodit-editor {
//   margin
// }

.meal-plan-card {
  padding: 12px;
  border: 1px solid #CED4DA;
  max-width: 100%;
  overflow: hidden;
  min-height: 200px;

  .food-image {
    width: 100%;
    height: 118px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 8px;
  }

  .food-title {
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 4px;
  }

  .food-info {
    color: #6A7187;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
}

.errors-popover {
  box-shadow: 0 0 3px #999;
}
