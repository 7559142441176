//Partners
$black-base-1:       #062021;
$black-base-3:       #657276;
$black-base-4:       #97A5A9;
$black-base-5:       #DDE2E3;
$accent-blue:        #3ECAE3;
$accent-blue--hover: #237699;
$white:              #FFFFFF;
$system-red:         #FF3C00;
$opacity-red:        #FF3C00BF;

$font-size-value--default: 16px;

.auth-partner-layout {
  height: 100vh;
  width: 100vw;
  padding: 40px;
  background-image: url("../images/partners/bg-image-partner.svg");
  background-attachment: scroll;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  color: $black-base-1;
  font-size: $font-size-value--default;

  *:not(.bx):not(.bx *):not(.invalid-feedback) {
    font-family: "Neue Montreal", sans-serif !important;
  }

  .auth-partner-layout-logo {
    min-height: 40px;
  }

  .partner-sign-up-section {
    gap: 2rem;
    padding-top: 21.8vh;

    .partner-sign-up-title {
      font-size: 56px;
      font-weight: 400;
      width: 432px;
      line-height: 120%;
      color: $black-base-1;
    }

    .partner-sign-up-form {
      width: 432px;

      .partner-sign-up-link {
        color: $accent-blue;
      }

      .partner-sign-up-link:hover {
        color: $accent-blue--hover;
      }

      .partner-sign-up-recaptcha-error {
        background-color: $opacity-red;
        border-radius: 8px;
        padding: 12px;
      }
    }
  }

  .partner-sign-up-google-captcha {
    margin-top: 10rem;
    color: $black-base-4;
    font-size: $font-size-value--default;
    line-height: 140%;
    font-weight: 400;
    width: 460px;
    text-align: center;
    margin-bottom: 40px;

    .auth-partner-link {
      color: $black-base-4;
      text-decoration: underline !important;
    }
  }

  .invalid-feedback {
    color: $system-red;
  }

  .partner-text-grey {
    color: $black-base-3;
  }

  .form-check-input:checked {
    border-color: $accent-blue;
    background-color: $accent-blue !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e") !important;
  }

  .form-control {
    font-size: $font-size-value--default;
    border-radius: 8px;
    padding: 13px 16px;
    height: 48px;
    color: $black-base-1;
  }

  .filter-toggle {
    font-size: $font-size-value--default;
    border-radius: 8px;
     padding: 9px 16px;
     height: 48px;
    min-height: 48px;
    height: auto;
  }

  .form-control:focus, .filter-toggle:focus {
     border-color: $accent-blue;
  }

  .form-check-input {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  .mt-4-5 {
    margin-top: 2rem;
  }

  .mx-4-5 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .btn-primary {
    border-radius: 36px;
    height: 48px;
    font-size: $font-size-value--default;
    background-color: $black-base-1 !important;
    border: none;
  }

  .btn-primary:hover {
    background-color: $black-base-3 !important;
  }

  .btn:disabled, .btn.disabled {
    color: $black-base-4;
    background-color: $black-base-5 !important;
    border: none;
  }

  .react-tel-input .form-control {
    padding-left: 58px;
    height: 48px;
    border-radius: 8px;
    color: $black-base-1;
  }

  .react-tel-input .selected-flag, .flag-dropdown, .react-tel-input .flag-dropdown, .react-tel-input .flag-dropdown.open .selected-flag  {
    border-radius: 8px 0 0 8px;
    width: 48px;
  }

  .react-tel-input .flag-dropdown {
    background-color: transparent;
  }

  .is-invalid ~ .flag-dropdown {
    border-left-color: #f46a6a;
    border-top-color: #f46a6a;
    border-bottom-color: #f46a6a;
  }

  .react-tel-input .selected-flag:hover, .react-tel-input .flag-dropdown.open .selected-flag {
    border-right: 1px solid #CED4DA;
  }

  .filter-dropdown .filter-toggle.with-border {
      border: 1px solid $accent-blue;
  }

  .badge.bg-primary {
    background-color: $accent-blue !important;
  }

  .partner-creating-account-section {
     .partner-creating-account-title {
        font-size: 56px;
        font-weight: 400;
        line-height: 120%;
        color: $black-base-1;
     }

     .partner-creating-account-logo {
             width: 64px;
             height: 64px;
          }
  }

  .auth-partner-logout {
    color: $black-base-1;
    border: 1px solid $black-base-5;
    border-radius: 36px;
    padding: 13px 32px;
    height: 48px;
    line-height: 1;
  }

  .auth-partner-logout:hover {
    border: 1px solid $accent-blue;
  }
  .partner-step-layout {
    width: 665px;
    margin-top: 64px;
    display: flex;
    flex-direction: column;

    .partner-step-title {
      color: $black-base-1;
      font-size: 36px;
    }

    .partner-credentials-item {
        background-color: $white;
        border: 1px solid $black-base-5;
        border-radius: 16px;
        padding: 16px;

        .partner-credentials-title-block {
            padding-bottom: 20px;
            border-bottom: 1px solid $black-base-5;
            .partner-credential-title {
                    color: $black-base-1;
                    font-weight: 500;
            }
        }

        .partner-credentials-form {
            border-bottom: 1px solid $black-base-5;
            padding-bottom: 24px;
        }
    }
  }

  .partner-text-primary {
    color: $accent-blue;
  }

  .partner-text-red {
    color: $system-red;
  }

  .partner-text-black {
      color: $black-base-1;
    }

  .react-datepicker__input-container, .react-datepicker__input-container > input {
    width: 100% !important;
    height: 48px;
    border-radius: 8px;
    font-size: $font-size-value--default;
  }

  .react-datepicker__input-container > input:focus {
    border-color: $accent-blue;
  }

  .partner-single-datepicker {
    width: 272px;
  }

  .react-datepicker__day--selected {
    background-color: $accent-blue !important;
  }

  .border-primary {
    border-color: $accent-blue !important;
  }

  .partner-document-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }

  #city.rbt-menu.dropdown-menu.show, #country.rbt-menu.dropdown-menu.show {
    border-radius: 16px;
    top: 55px !important;
    box-shadow: 0px 4px 25px 0px #00000014;
  }

  #city.rbt-menu.dropdown-menu.show > .dropdown-item,  #country.rbt-menu.dropdown-menu.show > .dropdown-item {
    font-size: $font-size-value--default;
    padding: 11px 16px;
  }

  .rbt.typeahead-invalid.is-invalid.without-error-icon > div > input {
    border-color: #f46a6a;
  }

}