.ItemsMenu {
    border-radius: 16px !important;
    margin-top: 8px !important;
}

.ItemsWrapper {
    max-height: 280px;
    overflow-y: scroll;
}

.Item {
    font-size: 16px;
    padding: 10px 16px;
}

.Badge {
    font-size: 16px !important;
    background-color: #3ECAE3 !important;
    padding: 4px 12px !important;
    border-radius: 15px !important;
    max-width: 380px;

    display: flex !important;
    align-items: center;
    justify-content: center;
}

.BadgeText {
    display: inline-block;
    max-width: 328px;
    overflow: hidden;
    text-overflow: ellipsis;
}


.Slide {
    background-color: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #CED4DA;
    color: #CED4DA;
    padding: 7px 16px;
    font-weight: 500;
    font-size: 13px;

    &.Active {
        border-bottom: 1px solid #556ee6;
        color: #556ee6;
    }
}