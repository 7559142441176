$app-preview-top-value: -25px;
$app-widget-preview-top-value: -20px;
$app-preview-width-value: 209px;
$app-preview-mood-size: 13.29px;

.dynamicIsland {
  position: sticky;
  background-color: #040404;
  width: 74px;
  height: 22px;
  border-radius: 12px;
  top: -1px;
  left: 68px;
  z-index: 2;
}

.topBarAvatar {
  position: absolute;
  width: 17.72px;
  height: 17.72px;
  top: 30px;
  left: 9px;
}

.topBarContainer {
  position: relative;
  top: $app-preview-top-value;
}

@mixin svg-style($height) {
  width: $app-preview-width-value;
  height: $height;
}

.topBarSvg { @include svg-style(54px); }
.dailyCaloriesSvg { @include svg-style(152px); }
.waterIntakeSvg { @include svg-style(83px); }
.deviceDataSvg { @include svg-style(256px); }
.mirrorMirrorMindSvg { @include svg-style(214px); }
.mirrorMirrorBodySvg { @include svg-style(250px); }
.fastingSvg { @include svg-style(170px); }
.moodSvg { @include svg-style(124px); }

@mixin mood-style($left: auto, $right: auto) {
  position: absolute;
  width: $app-preview-mood-size;
  height: $app-preview-mood-size;
  top: 47px;
  left: $left;
  right: $right;
}

.notGreatMood { @include mood-style(28px); }
.okMood { @include mood-style(78px); }
.goodMood { @include mood-style(auto, 75px); }
.greatMood { @include mood-style(auto, 25px); }

.dynamicBlock {
  position: relative;
  top: $app-widget-preview-top-value;
}
