body .impacts-section{
  padding: 24px 19px 24px 16px;
  background-color: #F8F9FA;
  display: flex;
  flex-direction: column;

  &__item{
    margin-bottom: 24px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }

  &__index{
    font-weight: normal;
    min-width: 15px;
    margin-right: 12px;
  }

  &__name{
    font-weight: normal;
    word-break: break-word;
    max-width: 90%;
  }

  &__edit{
    cursor: pointer;
    width: 16px;
    height: 20px;
    margin-right: 23px;
  }

  &__view{
    cursor: pointer;
    path{
      fill: #74788D;
    }
  }
}

.recommendation-icons{

  &__copy{
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-right: 29px;
  }

  &__edit{
    width: 14px;
    height: 16px;
    cursor: pointer;
    margin-right: 29px;
  }

  &__archive{
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-right: 28px;
  }

  &__trash{
    width: 15px;
    height: 17px;
    cursor: pointer;
  }
}

.recommendation-details{

  &__image{
    width: 240px;
    height: 240px;
    object-fit: contain;
  }
}

.recommendation-preview{
  background-color: #F8F9FA;
  margin-top: 16px;
  padding: 16px;

  &__image{
    width: 120px;
    height: 120px;
    margin-right: 16px;
  }

  &__title{
    word-break: break-all;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 16px;
  }
}

body .disabled-dropdown{
  pointer-events: none;


  .filter-toggle{
    background-color: #F2F2F2;
    border-color: transparent;
  }
}

.rec-filter{
  min-width: 250px;

  .rec-activity-filter, .rec-hierarchy-type-filter {
    min-width: 110px;
  }

  .rec-category-filter{
    min-width: 150px;
  }
}

body .type-ahead-menu{
  min-height: 80px;
  max-height: 250px;
  overflow-y: auto;

  .type-ahead-option{
    padding: 10px 12px;
    font-size: 13px;
    line-height: 20px;

    &:hover{
      background-color: #DEEBFF;
    }
  }

  .spinner{
    height: 100px;
  }
}